<template>
  <div class="profile-card" v-bind:class="{ 'hover' : hoverStyle }">
    <div class="profile-card-wrapper">
      <div class="swap-container" v-bind:class="{ 'hover' : hover, 'hover-enabled': !myprofile && hoverStyle && profile.headline && profile.aboutMe}">
        <div class="swapper">
          <div class="front">
          <mdc-card class="profile-wrapper">
            <div :class="'profile ' + profile.cardDesign">
              <div :class="'profile-bg ' + profile.cardDesignBackground">
                <span v-if="myprofile" @click="openCardDesign=!openCardDesign" class="mdc-icon duome-icon edit-icon">
                  <span class="mdc-icon mdc-icon--material material-icons">create</span>
                </span>
                <span v-if="myprofile" class="hover-info">Edit Design</span>
                <div v-if="myprofile" @click="avatarClick" class="profile-image editable">
                  <div class="overlay"></div>
                  <span v-if="profile" class="avatar">
                    <img v-if="hasPhoto(profile)" :src="profile.photoURL">
                    <img v-else src="/static/img/user.jpg">
                  </span>
                </div>
                <div v-else class="profile-image">
                  <span v-if="profile" class="avatar">
                    <img v-if="hasPhoto(profile)" :src="profile.photoURL">
                    <img v-else src="/static/img/user.jpg">
                  </span>
                </div>
                <div class="profile-text">
                  <mdc-subheading class="displayname">{{profile.displayName}}</mdc-subheading>
                  <img src="/static/img/mini-hr.svg">
                  <mdc-body class="profile-status" typo="body2">{{profile.headline || ''}}</mdc-body>
                </div>
              </div>
            </div>
          </mdc-card>
          </div>
          <div v-if="user.uid !== profile.id" class="back">
            <mdc-card class="profile-wrapper">
              <div :class="'profile'">
                <div :class="'profile-card-design ' + profile.cardDesign"></div>
                <div class="back-profile-overlay">
                  <div class="back-profile-content">
                    <mdc-body style="font-size: 0.875rem;" v-html="profile.aboutMe"></mdc-body>
                  </div>
                </div>
                <div :class="'profile-bg ' + profile.cardDesignBackground">
                </div>
              </div>
            </mdc-card>
          </div>
        </div>
      </div>
      <mdc-card v-if="detail" class="profile-details profile-details-old" v-bind:class="{ hover : hoverStyle }">
        <div class="link-row link-row-top">
          <div>
            <span class="mdc-icon duome-icon">
              <img src="/static/img/icons/location_outline_light.svg">
            </span>
            <div class="profile-stat">
              <mdc-body class="value">{{profile.location}}</mdc-body>
              <div v-if="profile.locationFull" class="hover-info">{{profile.locationFull}}</div>
              <span><img src="/static/img/separator.svg"></span>
              <mdc-body tag="span" class="field" typo="body2">Location</mdc-body>

            </div>
          </div>
          <div>
            <mdc-icon>
              <img src="/static/img/icons/watch_outline_light.svg">
            </mdc-icon>
            <div class="profile-stat">
              <mdc-body tag="span" class="value">{{flexDisplay}}</mdc-body>
              <span :class="'days-' + flexStyle">
                <span class="day"></span>
                <span class="day"></span>
                <span class="day"></span>
                <span class="day"></span>
                <span class="day"></span>
              </span>
              <mdc-body tag="span" class="field" typo="body2">Flex Schedule</mdc-body>
            </div>
          </div>
          <div>
            <mdc-icon>
              <img src="/static/img/icons/location_city_light.svg">
            </mdc-icon>
            <div class="profile-stat">
              <mdc-body tag="span" class="value">{{profile.workstyle}}</mdc-body>
              <span><img src="/static/img/separator.svg"></span>
              <mdc-body tag="span" class="field" typo="body2">Work style</mdc-body>
            </div>
          </div>
        </div>
        <div class="link-row link-row-bottom" style="margin-top:12px;">
          <div v-if="!myprofile">
            <connection-modal :profile="profile"></connection-modal>
          </div>
          <div v-if="!myprofile"></div>
          <div v-if="myprofile">
            <span @click.prevent.stop="shareopen = true">
              <mdc-icon >
                <img src="/static/img/icons/share_outline_light.svg">
              </mdc-icon>
              <div class="mdc-menu-surface--anchor">
                <mdc-menu class="sharemenu" v-model="shareopen">
                  <social-sharing @open="analytics" :url="`${publicProfilesUrl}/me/${profile.id}`" inline-template network-tag="li">
                    <div>
                    <network class="mdc-menu-item mdc-list-item" network="facebook">
                      <font-awesome-icon :icon="['fab', 'facebook']" /> Facebook
                    </network>
                    <network class="mdc-menu-item mdc-list-item" network="linkedin">
                      <font-awesome-icon :icon="['fab', 'linkedin']" /> Linkedin
                    </network>
                    <network class="mdc-menu-item mdc-list-item" network="twitter">
                      <font-awesome-icon :icon="['fab', 'twitter']" /> Twitter
                    </network>
                    <network class="mdc-menu-item mdc-list-item" network="whatsapp">
                      <font-awesome-icon :icon="['fab', 'whatsapp']" /> Whatsapp
                    </network>
                    </div>
                  </social-sharing>
                </mdc-menu>
              </div>
            </span>
          </div>

          <div v-if="!myprofile"
            v-bind:class="{ active: isActive }"
            @click.prevent.stop="isActive = !isActive"
          >
          <mdc-icon class="bookmark">
            <img class="icon" src="/static/img/icons/bookmark_border_light.svg">
            <img class="icon-active" src="/static/img/icons/bookmark_border_light_filled.svg">
          </mdc-icon>
          </div>
          <div></div>
          <div v-if="!myprofile && hoverStyle" class="mobileonly" @click.prevent.stop="hover = !hover" >
            <mdc-icon class="action" icon="more_horiz">
            </mdc-icon>
          </div>
        </div>
      </mdc-card>
    </div>

    <app-dialog v-model="openCardDesign"
      title="Select your card design..."
      accept=""
      cancel="cancel"
      class="open-card-design modal fullsize-mobile"
      scrollable>
      <div class="mdc-dialog__body">
        <div style="display:flex;flex-flow:row wrap;" v-for="(design, index) in carddesign" :key="index">
          <div  v-for="(layout, index) in cardlayout" :key="index"
          @click="selectCardDesign(design,layout)"
          class="profile-card" style="min-width: 250px;flex: 1;" >
            <div class="profile-card-wrapper">
              <mdc-card class="profile-wrapper">
                <div :class="'profile ' + design">
                  <div :class="'profile-bg ' + layout">
                    <div class="profile-image">
                      <span v-if="profile" class="avatar">
                        <img v-if="hasPhoto(profile)" :src="profile.photoURL">
                        <img v-else src="/static/img/user.jpg">
                      </span>
                    </div>
                    <div class="profile-text">
                      <mdc-subheading>{{profile.displayName}}</mdc-subheading>
                      <img src="/static/img/mini-hr.svg">
                      <mdc-body class="profile-status" typo="body2"></mdc-body>
                    </div>
                  </div>
                </div>
              </mdc-card>
            </div>
          </div>
        </div>
      </div>
    </app-dialog>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import ConnectionModal from '../components/ConnectionModal.vue'
import { publicProfilesUrl } from '../settings'
import AppDialog from '../components/AppDialog'

export default {
  name: 'ProfileCard',
  props: {
    profile: {
      type: Object,
      default: function () {
        return {}
      }
    },
    detail: {
      type: Boolean,
      default: true
    },
    user: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isActive: {
      type: Boolean,
      default: false
    },
    hoverStyle: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ConnectionModal,
    AppDialog
  },
  computed: {
    ...mapState([
      'company'
    ]),
    myprofile () {
      return this.profile.id === this.user.uid
    },
    flexDisplay () {
      const flexschedule = '' + (this.profile.flexschedule || '3')
      if (flexschedule.indexOf('Days') === -1) {
        return flexschedule + ' Days'
      }
      return flexschedule
    },
    flexStyle () {
      const flexschedule = this.profile.flexschedule || '3'
      return ('' + flexschedule).replace(' Days', '').replace('.', '-')
    },
    carddesign () {
      const basicCarddesign = ['profile-colour-cyan', 'profile-colour-magenta', 'profile-colour-orange', 'profile-colour-yellow', 'profile-colour-blue']
      const extraCarddesign = ['profile-design-2-white', 'profile-design-5-white', 'profile-design-6-white', 'profile-design-7-white']
      if (this.company.assignedCompany && this.company.assignedCompany.extraCarddesigns) {
        return [...extraCarddesign, ...basicCarddesign]
      }
      return basicCarddesign
    }
  },
  data () {
    return {
      hover: false,
      openCardDesign: false,
      cardlayout: ['profile-bg-diagonal', 'profile-bg-top', 'profile-bg-bottom', 'profile-bg-left'],
      shareopen: false,
      publicProfilesUrl
    }
  },
  methods: {
    avatarClick () {
      this.$emit('avatar')
    },
    selectCardDesign (design, layout) {
      this.$emit('cardDesign', design, layout)
      this.openCardDesign = false
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    analytics (payload) {
      // eslint-disable-next-line no-undef
      analytics.track('shareProfile', {
        id: this.profile.id,
        role: this.profile.role,
        company: this.profile.company,
        journeyStage: this.profile.journeyStage,
        platform: payload
      })
    }
  }
}
</script>

<style scoped>
.profile-card {
  min-width: 280px;
  max-width: 380px;
}
.displayname {
  display:inline-block;
  overflow-wrap: break-word;
  max-width:100%;
}
.sharemenu {
  min-width: 170px;
}
/* entire container, keeps perspective */

.open-card-design .mdc-dialog__body .profile-card {
  width: 46%;
  display: inline-block;
  margin: 2%;
  cursor: pointer;
}

.profile-stat .hover-info {
  display:none;
  position:absolute;
  color:#fff;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 12px;
  margin-top: 40px;
  border-radius:5px;
  font-size:12px;
  z-index:9999;
}
.profile-stat:hover .hover-info {
  display:flex;
}

.flip-container {
  perspective: 1000px;
  transform-style: preserve-3d;
}

/*  flip the pane when hovered */
.flip-container.hover-enabled:hover .back, .flip-container.hover-enabled.hover .back {
  transform: rotateY(0deg);
}
.flip-container.hover-enabled:hover .front, .flip-container.hover-enabled.hover .front  {
  transform: rotateY(180deg);
}

.flip-container, .front, .back {
  width: 100%;
  min-height:100px;
  height: auto;
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.swap-container .back, .swap-container .back {
  visibility: hidden;
  z-index:-1;
}
.swap-container.hover-enabled:hover .back, .swap-container.hover-enabled.hover .back {
  visibility: visible;
  z-index:4;
}

.swap-container.hover-enabled:hover .front, .swap-container.hover-enabled.hover .front {
  filter:blur(2px);
}

.swapper .back .profile-wrapper {
  background-color: rgba(110, 110, 110, 0.5);
}

.swapper .back .profile-bg {
  display:none;
}

.profile-card.hover:hover .swapper .profile-wrapper {
  background:none;
}

.swapper .back .profile .back-profile-overlay {
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.5);
  color:#fff !important;
  position:absolute;
  overflow: hidden;
  z-index:1;
}

.swapper .back .profile .back-profile-overlay p {
  color:#fff !important;
}

.swapper .back .profile .profile-card-design {
  height:100%;
  width:100%;
  position:absolute;
  background:none;
  filter:blur(1px);
}

/* hide back of pane during swap */
.flipper .front, .flipper .back {
  backface-visibility: hidden;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  top: 0;
  left: 0;
}

.back {
  margin-top:-65%;
}

.back .profile-bg {
  filter:blur(1px);
  margin-left:-2px;
}

.flipper .back .profile .back-profile-overlay {
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.3);
  color:#fff !important;
  position:absolute;
  overflow: hidden;
  z-index:1;
}

/* front pane, placed above back */
.flipper .front {
  z-index: 2;
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.flipper .back {
  transform: rotateY(-180deg);
}

.flipper .back .profile .profile-card-design {
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  filter:blur(1px);
}

.back .profile .back-profile-overlay .back-profile-content{
  margin-top:16px;
  margin-left:6px;
  margin-right:6px;
  overflow: hidden;
}

.profile-wrapper {
  background-color:white;
  border-radius: 13px;
  width:100%;
  overflow: hidden;
  padding-top: calc(154 / 238 * 100%);
  position: relative;
}

.safari .profile-wrapper {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.profile, .profile-bg {
  display:flex;
  flex-flow: row;
  position: absolute;
  top: 0px;
  left: 0;
  width: 101%;
  height: 100%;
  align-items: center;
}

.profile {
  top:-8px;
  background-size: cover;
  height:calc(100% + 16px);
}

/* Background position this goes on the same div as profile-bg class */
div[class*="profile-bg-"] .profile-text {
  padding-bottom: 0px;
  padding-left:0px;
}

div[class*="profile-bg-"] .profile-text img {
  display: none;
}

.profile-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #F2EEE6 0%);
}
.profile-card:hover .profile-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #DEDBD6 0%);
}
.profile-bg-top {
  background: linear-gradient(#F2EEE6 85%, transparent 0%);
}
.profile-bg-top .profile-text {
  padding-top: 0;
  padding-bottom: 20px;
}
.profile-card:hover .profile-bg-top {
  background: linear-gradient(#DEDBD6 85%, transparent 0%);
}
.profile-bg-bottom {
  background: linear-gradient(transparent 40%, #F2EEE6 0%);
}
.profile-card:hover .profile-bg-bottom {
  background: linear-gradient(transparent 40%, #DEDBD6 0%);
}
.profile-bg-left {
  background: linear-gradient(90deg, transparent 22%, #F2EEE6 0%);
}
.profile-card:hover .profile-bg-left {
  background: linear-gradient(90deg, transparent 22%, #DEDBD6 0%);
}

div[class*="profile-bg-"] .profile-text {
  padding-bottom: 0px;
}

div[class*="profile-bg-"] .profile-text img {
  display: none;
}

/* Background colour/bg options this goes on the same div a profile class */
.profile-colour-cyan {
  background-color: #2cbcb6;
}
.profile-colour-magenta {
  background-color: #C10457;
}
.profile-colour-orange {
  background-color: #f4854d;
}
.profile-colour-yellow {
  background-color: #ffd12b;
}
.profile-colour-blue {
  background-color: #3560AF;
}
.profile-design-1-cyan {
  background-image: url('/static/img/card-designs/profile-design-1-cyan.svg');;
}
.profile-design-1-magenta {
  background-image: url('/static/img/card-designs/profile-design-1-magenta.svg');
}
.profile-design-1-orange {
  background-image: url('/static/img/card-designs/profile-design-1-orange.svg');
}
.profile-design-1-yellow {
  background-image: url('/static/img/card-designs/profile-design-1-yellow.svg');
}
.profile-design-2-cyan {
  background-image: url('/static/img/card-designs/profile-design-2-cyan.svg');
}
.profile-design-2-white {
  background-image: url('/static/img/card-designs/profile-design-2-white.svg');
}
.profile-design-2-magenta {
  background-image: url('/static/img/card-designs/profile-design-2-magenta.svg');
}
.profile-design-2-orange {
  background-image: url('/static/img/card-designs/profile-design-2-orange.svg');
}
.profile-design-2-yellow {
  background-image: url('/static/img/card-designs/profile-design-2-yellow.svg');
}
.profile-design-3-cyan {
  background-image: url('/static/img/card-designs/profile-design-3-cyan.svg');
}
.profile-design-3-magenta {
  background-color: #C10457;
  background-image: url('/static/img/card-designs/profile-design-3-magenta.svg');
}
.profile-design-3-orange {
  background-image: url('/static/img/card-designs/profile-design-3-orange.svg');
}
.profile-design-3-yellow {
  background-image: url('/static/img/card-designs/profile-design-3-yellow.svg');
}
.profile-design-4-cyan {
  background-image: url('/static/img/card-designs/profile-design-4-cyan.svg');
}
.profile-design-4-magenta {
  background-image: url('/static/img/card-designs/profile-design-4-magenta.svg');
}
.profile-design-4-orange {
  background-image: url('/static/img/card-designs/profile-design-4-orange.svg');
}
.profile-design-4-yellow {
  background-image: url('/static/img/card-designs/profile-design-4-yellow.svg');
}
.profile-design-5-cyan {
  background-image: url('/static/img/card-designs/profile-design-5-cyan.svg');
}
.profile-design-5-white {
  background-image: url('/static/img/card-designs/profile-design-5-white.svg');
}
.profile-design-5-magenta {
  background-image: url('/static/img/card-designs/profile-design-5-magenta.svg');
}
.profile-design-5-orange {
  background-image: url('/static/img/card-designs/profile-design-5-orange.svg');
}
.profile-design-5-yellow {
  background-image: url('/static/img/card-designs/profile-design-5-yellow.svg');
}
.profile-design-6-cyan {
  background-image: url('/static/img/card-designs/profile-design-6-cyan.svg');
}
.profile-design-6-white {
  background-image: url('/static/img/card-designs/profile-design-6-white.svg');
}
.profile-design-6-magenta {
  background-image: url('/static/img/card-designs/profile-design-6-magenta.svg');
}
.profile-design-6-orange {
  background-image: url('/static/img/card-designs/profile-design-6-orange.svg');
}
.profile-design-6-yellow {
  background-image: url('/static/img/card-designs/profile-design-6-yellow.svg');
}
.profile-design-6-multi {
  background-image: url('/static/img/card-designs/profile-design-6-multi.svg');
}
.profile-design-7-cyan {
  background-image: url('/static/img/card-designs/profile-design-7-cyan.svg');
}
.profile-design-7-white {
  background-image: url('/static/img/card-designs/profile-design-7-white.svg');
}
.profile-design-7-magenta {
  background-image: url('/static/img/card-designs/profile-design-7-magenta.svg');
}
.profile-design-7-orange {
  background-image: url('/static/img/card-designs/profile-design-7-orange.svg');
}
.profile-design-7-yellow {
  background-image: url('/static/img/card-designs/profile-design-7-yellow.svg');
}
.profile-image {
  width: 48%;
}
.avatar {
  width: 80%;
  margin: 10%;
  padding-top: 80%;
  position: relative;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: block;
  overflow: hidden;
}
.avatar img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;;
    left: 0;
    object-fit: cover;
}
.profile-text {
  width: 52%;
  height: 50%;
  text-align: center;
  padding: 5%;
  padding-top: 30% !important;
  overflow: hidden;
}
.link-row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.link-row > div {
  flex: 1;
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
}
.link-row-top > div {
  flex: 0;
}
.link-row-bottom > div:nth-of-type(3) {
    flex: 0 0 30%;
    min-width: 30px;
    justify-content: flex-end;
}
.link-row-bottom > div:nth-of-type(4) {
    flex: 0 0 15%;
    min-width: 30px;
    justify-content: flex-end;
}
.profile-stat {
    display: flex;
    flex-direction: column;
    line-height: 0;
}
.profile-headline {
  font-style:italic;
  font-size: 14px;
  color: #333333;
}
.profile-card .profile-image {
  position:relative;
}
.profile-card .mdc-body.profile-status {
  max-width: calc(100% + 12px);
  overflow: hidden;
}
.profile-card .profile-image .profile-status {
  color:gray;
}
.profile-card .profile-image.editable:hover {
  cursor:pointer;
}
.profile-card .profile-image.editable .overlay{
  display:none;
  position:absolute;
  width:100%;
  height:100%;
}
.profile-card .profile-image.editable:hover .overlay{
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/424395/camera.svg");
  background-size: 50px 41px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.7;
  z-index:9;
  display:block;
}
.profile-card .mdc-subheading {
  margin:0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.profile-card .mdc-body {
  margin:0;
  font-size: 12px;
  line-height: 1;
}
span[class^="days-"] {
  margin-top: 3px;
  width: 55px;
  white-space: nowrap;
}
span[class^="days-"] .day {
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 8px;
  margin-left: 1.5px;
  margin-right: 1.5px;
}
span.days-1 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4 .day:nth-of-type(-n+4) {
  background: #AD1457;
}
span.days-5 .day:nth-of-type(-n+5) {
  background: #AD1457;
}

span.days-1-5 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2-5 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3-5 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4-5 .day:nth-of-type(-n+4) {
  background: #AD1457;
}

span.days-0-5 .day:nth-of-type(1) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-1-5 .day:nth-of-type(2) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-2-5 .day:nth-of-type(3) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-3-5 .day:nth-of-type(4) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-4-5 .day:nth-of-type(5) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}

.profile-status-bar {
  display:flex;
  width:100%
}
.profile-details {
  padding: 5% 2% 0 2%;
  font-family: 'Roboto', sans-serif;
  background: none;
  box-shadow: none;
}
.profile-details .duome-icon img {
  width:20px;
  height: 20px;
}
.profile .edit-icon {
  background: #fff;
  position: absolute;
  bottom: 12px;
  left: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex;
  margin: 0;
}
.profile .edit-icon .mdc-icon.mdc-icon--material {
  font-size: 20px;
  line-height: 25px;
  color: #888;
  margin-right: 0;
}
.profile .edit-icon:hover {
  cursor:pointer;
}
.profile .edit-icon + .hover-info {
  display:none;
  position:absolute;
  color:white;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 12px;
  border-radius:5px;
  font-size:12px;
  z-index: 101;
  left: 30px;
  bottom: 25px;
}
.profile .edit-icon:hover + .hover-info {
  display:flex;
}
.profile-details .value {
    display: block;
    line-height: 1;
    color: #333333;
    min-height: 9px;
}
.profile-details .field {
  font-style: italic;
  margin-top: 3px;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.location .profile-stat .value {
  font-style:italic;
  font-size: 14px;
}
.role {
  font-weight: 500;
  font-style:italic;
  font-size: 14px;
}
.mdc-icon {
  margin-right: 5px;
}
.profile-card:not(:hover) .link-row-bottom .mdc-button {
  background-color: #ffffff;
  box-shadow: none;
  color: #333333;
}
.profile-card .link-row-bottom .mdc-button .mdc-icon {
  margin: 0 10px 5px 0;
}
.profile-details .mdc-button {
  color: black;
}
.profile-details .mdc-button img {
  margin-right:6px;
}
.profile-details .mdc-button img.icon-active {
  display:none;
}
.profile-card.hover:hover .profile-wrapper {
  background: #F6F6F6;
}
.about > div {
  font-size: 14px;
  margin: 10px 0;
}
.action {
  cursor: pointer;
}
.bookmark {
  display:none;
  cursor: pointer;
}
.bookmark .icon {
  display: block;
}
.bookmark .icon-active {
  display:none;
}
.active .bookmark .icon-active {
  display: block;
}
.active .bookmark .icon {
  display: none;
}
.modal .mdc-dialog__container {
  width: calc(100% - 20px);
  min-width: 340px !important;
  max-width: 885px !important;
}
</style>

<style>
/** Not Scoped for hover styling sub component **/
.profile-details-old.hover:hover .connection-modal .connection-modal-btn.mdc-button {
  background-color: rgb(45, 156, 219) !important;
  color:white !important;
}
.profile-details-old.hover:hover .connection-modal .connection-modal-btn.mdc-button .icon-active{
  display:block !important;
}
.profile-details-old.hover:hover .connection-modal .connection-modal-btn.mdc-button .icon{
  display:none !important;
}

.profile-card .open-card-design .mdc-dialog__surface {
  height: calc(100vh - 80px);
  min-height: 400px;
}
.profile-card .open-card-design .mdc-dialog__body--scrollable {
  height:100%;
  max-height:100%;
}
</style>
